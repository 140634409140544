import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { LayoutShell, SiteFooterLayout, UiConfig, UiProvider, WARM_GREY } from '@shapeable/ui';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { HALO_SKY_BLUE, LayoutStyles, themeOverrides } from '../theme';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;
  
  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    menuBar: {
      backgroundColor: HALO_SKY_BLUE,
      isInverted: true,
    },
    header: {
      variant: 'overlay',
      activeColor: HALO_SKY_BLUE,
    },
  }

  return (
    <UiProvider value={config}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={WARM_GREY} />
      </My.Container>
    </UiProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

